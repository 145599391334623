import { useEffect, useState, useRef } from "react";
import { Affix, Button, Row, Col, Radio, Input, Table, Modal, Form, Switch, message  } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import "antd/dist/antd.css";
//import Map from './LayersMap/Map'
import BaseMap from "./LayersMap/BaseMap";
import Filters from "../Filters";
import Legend from './LayersMap/Legend/'
import HeaderDetails from "./HeaderDetails";
import Donut from "./Charts/DonutChart/DonutIndex";
import { ChartDivider } from "./Charts/LineChart/ChartsDivider";
import Histogram from "./Charts/HistogramCharts";
import "./Audience.css";
import { API } from "aws-amplify";
import { iconArrowUp, iconArrowDown } from '../../Helpers/Icons'
import LineChart from "./Charts/LineCharts";
import TableBehavoirs from "./Table/Table";

const styleTopBottom = {
  backgroundColor: "#391EDE",
  color: "#FFFFFF",
  position: "fixed",
  width: 48,
  height: 48,
  right: 50,
  bottom: 45,
  zIndex: 2,
};

const Audience = () => {
  const [filters, setFilters] = useState([])
  const [comportamientos, setComportamientos] = useState([])
  const [ubicaciones, setUbicaciones] = useState([])
  const [genero, setGenero] = useState([])
  const [ingreso, setIngreso] = useState([])
  const [edad, setEdad] = useState([])
  const [marca, setMarca] = useState([])
  const [tipo, setTipo] = useState([])
  const [buttonClicked, setButtonClicked] = useState(false);
  const [sistemaOperativo, setSistemaOperativo] = useState([])
  const [dateNavigation, setDateNavigation] = useState([])
  const [dateNavigationRange, setDateNavigationRange] = useState(["", ""])
  const [rangeNavigationDate, setRangeNavigationDate] = useState()
  const [hourNavigation, setHourNavigation] = useState([])
  const [hourNavigationRange, setHourNavigationRange] = useState(["", ""])
  const [rangeNavigationHour, setRangeNavigationHour] = useState()
  const [dataBaseExternal, setDataBaseExternal] = useState(null)
  const [typeLocation, setTypeLocation] = useState(1)
  const [dbExternalName ,setDBExternalName] = useState(null)
  const [showFilters, setShowFilters] = useState(false);
  const [totalAudience, setTotalAudience] = useState();
  const [totalNewAudience, setTotalNewAudience] = useState();
  const [loadingTotalNewAudience, setLoadingTotalNewAudience] = useState(false)
  const [totalBE, setTotalBE] = useState();
  const [loadingTotalBE, setLoadingTotalBE] = useState(false)
  const [backToTop, setBackToTop] = useState(false);
  const [backToIcon, setBackToIcon] = useState(<DownOutlined />);

  const [colors, setColors] = useState(["#009F66", "#F12D12"])
  const [maxCountLocation, setMaxCountLocations] = useState()

  let minColor = useRef("#009F66")
  let maxColor = useRef("#F12D12")

  let params = useParams()
  //console.log(filters);
  const [dataAudience, setDataAudience] = useState({ name: 'Nueva Audiencia', filters: [] });


  const [dataMap, setDataMap] = useState({ total: null, newAudience: null, dataExternalBD: null })

  const [dataTotal, setDataTotal] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderTotal, setShowLoaderTotal] = useState(true)

  const [dataDias, setDataDias] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderDias, setShowLoaderDias] = useState(true)

  const [dataHoras, setDataHoras] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderHoras, setShowLoaderHoras] = useState(true)

  const [dataGenero, setDataGenero] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderGenero, setShowLoaderGenero] = useState(true)

  const [dataIngreso, setDataIngreso] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderIngreso, setShowLoaderIngreso] = useState(true)

  const [dataEdad, setDataEdad] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderEdad, setShowLoaderEdad] = useState(true)

  const [dataMarca, setDataMarca] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderMarca, setShowLoaderMarca] = useState(true)

  const [dataTipo, setDataTipo] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderTipo, setShowLoaderTipo] = useState(true)

  const [dataSistema, setDataSistema] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderSistema, setShowLoaderSistema] = useState(true)

  const [dataComportamientos, setDataComportamientos] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderComportamientos, setShowLoaderComportamientos] = useState(true)

  const [dataDiasTotales, setdataDiasTotales] = useState({ total: null, newAudience: null, dataExternalBD: null })
  const [showLoaderDiasTotales, setShowLoaderDiasTotales] = useState(true)

  const [topComportamientos, setTopComportamientos] = useState({ value: null, name: null })
  const [topUbicaciones, setTopUbicaciones] = useState({ value: null, name: null })
  const [topGeneros, setTopGeneros] = useState({ value: null, name: null })
  const [topIngresos, setTopIngresos] = useState({ value: null, name: null });
  const [topEdad, setTopEdad] = useState({ value: null, name: null });
  const [topMarca, setTopMarca] = useState({ value: null, name: null });
  const [topDispositivos, setTopDispositivos] = useState({ value: null, name: null });
  const [topSO, setTopSO] = useState({ value: null, name: null });

  const [modalFiltersOpen, setModalFiltersOpen] = useState(false);
  const [switchUbicaciones,setSwitchUbicaciones] = useState(false)
  const [switchComportamientos,setSwitchComportamientos] = useState(false)
  const [switchGeneros,setSwitchGeneros] = useState(false)
  const [switchEdades,setSwitchEdades] = useState(false)
  const [switchMarcas,setSwitchMarcas] = useState(false)
  const [switchTipos,setSwitchTipos] = useState(false)
  const [switchIngresos,setSwitchIngresos] = useState(false)
  const [switchSO,setSwitchSO] = useState(false)
  const [switchAllAudience,setSwitchAllAudience] = useState(true)
  const [switchNoUsed,setSwitchNoUsed] = useState(true)
  const [filtersActived,setFiltersActived] = useState(true)
  const [filtersApplied,setFiltersApplied] = useState(false)


  const views = [
    { name: 'getCountHistory', setter: setdataDiasTotales, state: dataDiasTotales, onHandleLoader: setShowLoaderDiasTotales, switchHandler: switchNoUsed },
    { name: 'getLocation', setter: setDataMap, state: dataMap, onHandleLoader: () => true, switchHandler: switchUbicaciones },
    { name: 'getAllAudiences', setter: setDataTotal, state: dataTotal, onHandleLoader: setShowLoaderTotal, switchHandler: switchAllAudience },
    { name: 'getDates', setter: setDataDias, state: dataDias, onHandleLoader: setShowLoaderDias, switchHandler: switchNoUsed },
    { name: 'getHours', setter: setDataHoras, state: dataHoras, onHandleLoader: setShowLoaderHoras, switchHandler: switchNoUsed  },
    { name: 'getGender', setter: setDataGenero, state: dataGenero, onHandleLoader: setShowLoaderGenero, switchHandler: switchGeneros },
    { name: 'getIngresos', setter: setDataIngreso, state: dataIngreso, onHandleLoader: setShowLoaderIngreso, switchHandler: switchIngresos },
    { name: 'getAges', setter: setDataEdad, state: dataEdad, onHandleLoader: setShowLoaderEdad, switchHandler: switchEdades },
    { name: 'getBranchDevices', setter: setDataMarca, state: dataMarca, onHandleLoader: setShowLoaderMarca, switchHandler: switchMarcas },
    { name: 'getTypeDevices', setter: setDataTipo, state: dataTipo, onHandleLoader: setShowLoaderTipo, switchHandler: switchTipos },
    { name: 'getOsDevices', setter: setDataSistema, state: dataSistema, onHandleLoader: setShowLoaderSistema, switchHandler: switchSO },
    { name: 'getBehavior', setter: setDataComportamientos, state: dataComportamientos, onHandleLoader: setShowLoaderComportamientos, switchHandler: switchComportamientos }
  ]

  const getAudience = async ({ queryName, filters, idClm }) => {
    let elapsedTime = 0;
    const maxTimeout = 60000; // 1 minuto en milisegundos
    while (elapsedTime < maxTimeout) {
      try {
        const result = await API.graphql({
          query: `query MyQuery ($input: inputFilters){
            getFiltersRedshift(input: $input){
              key
              type_filter
              description_filter
              value_filter
              type_behaviour
              is_app
              subscribers
              id_statement
            }
          }`,
          variables:
          {
            input:{query: queryName, filters: filters, idClm: idClm}
          }
        });
        const data = result.data.getFiltersRedshift;
        if (data !== null) {
          return data;
        } else {
          await delay(5000); // Esperar 5 segundos antes de intentar nuevamente
          elapsedTime += 5000;
        }
      } catch (err) {
        console.log('Error consultando', err.errors);
        return null;
      }
    }
    return null;
  };

  const delay = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const getValues = async ({idStatement, query, setter, state, value}) => {
    let response = [];
    while (response.length === 0) {
      const result = await API.graphql({
        query: `query MyQuery {
          getData(input: {id_statement: "${idStatement}", query: "${query}"}){
            key
            description_filter
            value_filter
            is_app
            subscribers
          }
        }`
      });
      response = result.data.getData;
      if (response.length === 0) {
        await new Promise(resolve => setTimeout(resolve, 5000)); // Esperar 5 segundos antes de hacer la siguiente petición
      }
    }
    return response;
  };

  const queries = async (typeData, filters = null, idClm = null) => {
    const key = 'loadingKey';
    message.loading({
      key,
      content: 'Cargando indicadores.',
      duration: 0,
      style: {
        marginTop: '7vh'
      },
    });
    for (let i = 0; i < views.length; i++) {
      const { name, setter, state, onHandleLoader, switchHandler } = views[i];
      onHandleLoader(true);
      let values_response;

      if ((name === "getDates" && filters && !filters.some(filter => filter.type_filter === 9)) || (name === "getHours" && filters && !filters.some(filter => filter.type_filter === 10) || (name === "getCountHistory" && filters ))){
        setShowLoaderDiasTotales(false)
        continue;
      }
      if (filters === null) {
        values_response = await getAudience({ queryName: name, filters: filters, idClm: idClm });

      } 
      else if (!switchHandler && !filtersActived) {
        continue
      }
      else {
        let new_filters = [];
        filters.forEach(obj => {
          if (obj.description_filter === "estado_all") {
            // Crear dos nuevos objetos con descripciones diferentes
            let newObjWork = { ...obj, description_filter: "estado_work" };
            let newObjHome = { ...obj, description_filter: "estado_home" };
        
            // Agregar los nuevos objetos al nuevo array
            new_filters.push(newObjWork, newObjHome);
          } else {
            // Si la descripción no es "estado_all", simplemente copiar el objeto original
            new_filters.push(obj);
          }
        });
        const response = await getAudience({ queryName: name, filters: new_filters, idClm: idClm });
        values_response = await getValues({ idStatement: response[0].id_statement, query: name });
      }
      if (idClm == null){
        switch (typeData) {
          case 1:
            // Total Audience
            setter({ ...state, total: values_response });
            break;
          case 2:
            // New Audience
            if (name !== "getCountHistory") setter({ ...state, newAudience: values_response, dataExternalBD: null });
            break;
          case 3:
            // CLM
            if (name !== "getCountHistory") setter({ ...state, newAudience: values_response, dataExternalBD: null });
            break;
          default:
            // undefined
            break;
        }
      }else{
        switch (typeData) {
            case 1:
              // Total Audience
              setter({ ...state, total: values_response });
              break;
            case 2:
              // New Audience
              if (name !== "getCountHistory") setter({ ...state, dataExternalBD: values_response, newAudience: null });
              break;
            case 3:
              // CLM
              if (name !== "getCountHistory") setter({ ...state, dataExternalBD: values_response, newAudience: null });
              break;
            default:
              // undefined
              break;
        }
      }
      onHandleLoader(false);
    }
    message.success({
      key,
      content: 'La carga de indicadores se realizó satisfactoriamente.',
      duration: 4,
      style: {
        marginTop: '7vh'
      },
    }); 
  };

  useEffect(() => {
    if (params.id) {
      getData(params);
    }
    window.onscroll = toBottomTop;
    queries(1)
  }, [])

  useEffect(() => {
    if (dataComportamientos.total && params.id && filters.length !== 0) {
      if (!(dataComportamientos.dataExternalBD || dataComportamientos.newAudience)) {
          setModalFiltersOpen(true)
      }
    }
  }, [dataComportamientos]);

  useEffect(() => {
    if (filters.length !== 0 && dataComportamientos.total){
    queries(2, filters, dataBaseExternal)
    setFiltersApplied(true)}
    else if (filtersApplied){
      queries(1)
    }
  }, [filters, dataBaseExternal])

  const getData = async (params) => {
    const allTodos = await API.graphql({
      query: `
      query GetAudience($id: ID!) {
        getAudience(id :$id) {
          id
          audienceUserId
          name
          filters {
            key
            description_filter
            type_filter
            value_filter
            type_behaviour
          }
          audienceClmId
      }
    }`,
      variables: {
        id: params.id,
      },
    });
    if (allTodos.data.getAudience) {
      setDataAudience(allTodos.data.getAudience);
    }
    if (allTodos.data.getAudience.audienceClmId) {
      setDataBaseExternal(allTodos.data.getAudience.audienceClmId);
    }
  };

  useEffect(() => {
    if (dataAudience.filters) {
      dataAudience.filters.forEach(item => {
        switch (item.type_filter) {
          case 1:
            setGenero((prevArray) => [...prevArray, item])
            break;
          case 2:
            setEdad((prevArray) => [...prevArray, item])
            break;
          case 3:
            setIngreso((prevArray) => [...prevArray, item])
            break;
          case 4:
            setUbicaciones((prevArray) => [...prevArray, item])
            break;
          case 5:
            setComportamientos((prevArray) => [...prevArray, item])
            break;
          case 6:
            setMarca((prevArray) => [...prevArray, item])
            break;
          case 7:
            setSistemaOperativo((prevArray) => [...prevArray, item])
            break;
          case 8:
            setTipo((prevArray) => [...prevArray, item])
            break;
          case 9:
            setRangeNavigationDate(item)
            setDateNavigationRange([...item.value_filter.split(',')])
            break;
          case 10:
            setRangeNavigationHour(item)
            setHourNavigationRange([...item.value_filter.split(',')])
            break;
          default:
            break;
        }
      })
    }
  }, [dataAudience]);

  useEffect(() => {
    const auxFilters = [];
    comportamientos.forEach(item => auxFilters.push(item));
    ubicaciones.forEach(item => {
      let filter = { ...item };
      delete filter.subscribers;
      auxFilters.push(filter);
    });
    genero.forEach(item => auxFilters.push(item));
    ingreso.forEach(item => auxFilters.push(item));
    edad.forEach(item => auxFilters.push(item));
    marca.forEach(item => auxFilters.push(item));
    tipo.forEach(item => auxFilters.push(item));
    sistemaOperativo.forEach(item => auxFilters.push(item));
    dateNavigation.forEach(item => auxFilters.push(item));
    hourNavigation.forEach(item => auxFilters.push(item));
    if (rangeNavigationDate) {
      auxFilters.push(rangeNavigationDate);
    }
    if (rangeNavigationHour) {
      auxFilters.push(rangeNavigationHour);
    }
    setFilters(auxFilters);
  }, [buttonClicked]);

  useEffect(() => {
    if (dateNavigationRange[0] !== "") {
      const auxDateString = `${dateNavigationRange[0]},${dateNavigationRange[1]}`
      const filterDate = {
        "key": "1",
        "type_filter": 9,
        "description_filter": "fecha",
        "value_filter": auxDateString
      };
      setRangeNavigationDate(filterDate)
    }
  }, [dateNavigationRange])

  useEffect(() => {
    if (hourNavigationRange[0] !== "") {
      const auxHourString = `${hourNavigationRange[0]},${hourNavigationRange[1]}`
      const filterHour = {
        "key": "1",
        "type_filter": 10,
        "description_filter": "hora",
        "value_filter": auxHourString
      };
      setRangeNavigationHour(filterHour)
    }
  }, [hourNavigationRange])

  useEffect(() => {
    setBackToIcon(backToTop ? <UpOutlined /> : <DownOutlined />);
  }, [backToTop]);

  const toBottomTop = () => {
    if (document.documentElement.scrollTop >= 270) {
      setBackToTop(true);
    } else {
      setBackToTop(false);
    }
  };

  return (
    <div>
      <Button
        style={styleTopBottom}
        shape="circle"
        icon={backToIcon}
        onClick={() => {
          if (backToTop) {
            document.documentElement.scrollTop = 0;
          } else {
            document.documentElement.scrollTop = 915;
          }
        }}
      />
      <div
        style={{
          position: "fixed",
          backgroundColor: "white",
          borderRadius: 5,
          zIndex: "100",
          width: "91%",
          top: 50.55,
        }}
      >

        <div className={!showFilters ? "FiltersComponent" : "FiltersComponent open"} >

          <Filters
            filters={filters}
            setFilters={setFilters}
            comportamientos={comportamientos}
            setComportamientos={setComportamientos}
            ubicaciones={ubicaciones}
            setUbicaciones={setUbicaciones}
            genero={genero}
            setGenero={setGenero}
            ingreso={ingreso}
            setIngreso={setIngreso}
            edad={edad}
            setEdad={setEdad}
            marca={marca}
            setMarca={setMarca}
            tipo={tipo}
            setTipo={setTipo}
            sistemaOperativo={sistemaOperativo}
            setSistemaOperativo={setSistemaOperativo}
            dateNavigationRange={dateNavigationRange}
            setDateNavigationRange={setDateNavigationRange}
            hourNavigationRange={hourNavigationRange}
            setHourNavigationRange={setHourNavigationRange}
            dataBaseExternal={dataBaseExternal}
            setDataBaseExternal={setDataBaseExternal}
            setDBExternalName={setDBExternalName}
          />

        </div>
        <Button
          size="large"
          onClick={() => {
            setShowFilters(!showFilters)
            if (showFilters) {
              setModalFiltersOpen(true);
            }
          }}
          className={!showFilters ? "ButtonFiltros SubtituloRegularGA" : "ButtonFiltros SubtituloRegularGA OpenFiltros"}
        >
          {!showFilters ? <>
            <Row>
              <Col style={{ textAlign: "left" }} span={20}>
                Mostrar Filtros
              </Col>
              <Col span={4}>
                {!showFilters ? iconArrowUp : iconArrowDown}
              </Col>
            </Row>
          </>
            :
            <>
              <Row>
                <Col style={{ textAlign: "left" }} span={21}>
                  Aplicar Filtros
                </Col>
                <Col span={2} style={{ marginRight: "5px" }}>
                  {!showFilters ? iconArrowUp : iconArrowDown}
                </Col>
              </Row>
            </>
          }
        </Button>
        <Modal
        clasName="ModalGA"
        title="INDICADORES A VISUALIZAR"
        maskClosable= "false"
        open={modalFiltersOpen}
        onCancel={() => setModalFiltersOpen(false)}
        footer={[
          <Button key="back" onClick={() => {setModalFiltersOpen(false)}}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={() => {
              setModalFiltersOpen(false);
              setFiltersActived(false);
              setButtonClicked(prevState => !prevState);     }} 
            >
            Aceptar
          </Button>,
        ]}
      >
      <Form
          labelCol={{
            span: 8,
            offset: 6
          }}
          wrapperCol={{
            span: 4,
          }}
          layout="horizontal"
          style={{
            maxWidth: 600,
          }}
        >
          <Form.Item label="Ubicaciones" style={{fontFamily:'Roboto-Medium', fontSize:'16px', marginBottom: 8}} labelAlign="left" valuePropName="checked">
            <Switch onChange={() => setSwitchUbicaciones(prevState => !prevState)} checked={switchUbicaciones}/>
          </Form.Item>
          <Form.Item label="Géneros" style={{fontFamily:'Roboto-Medium', fontSize:'16px', marginBottom: 8}} labelAlign="left" valuePropName="checked">
            <Switch onChange={() => setSwitchGeneros(prevState => !prevState)} checked={switchGeneros}/>
          </Form.Item>
          <Form.Item label="Ingresos" style={{fontFamily:'Roboto-Medium', fontSize:'16px', marginBottom: 8}} labelAlign="left" valuePropName="checked">
            <Switch onChange={() => setSwitchIngresos(prevState => !prevState)} checked={switchIngresos}/>
          </Form.Item>
          <Form.Item label="Edades" style={{fontFamily:'Roboto-Medium', fontSize:'16px', marginBottom: 8}} labelAlign="left" valuePropName="checked">
            <Switch onChange={() => setSwitchEdades(prevState => !prevState)} checked={switchEdades}/>
          </Form.Item>
          <Form.Item label="Marcas" style={{fontFamily:'Roboto-Medium', fontSize:'16px', marginBottom: 8}} labelAlign="left" valuePropName="checked">
            <Switch onChange={() => setSwitchMarcas(prevState => !prevState)}checked={switchMarcas}/>
          </Form.Item>
          <Form.Item label="Tipos" style={{fontFamily:'Roboto-Medium', fontSize:'16px', marginBottom: 8}} labelAlign="left" valuePropName="checked">
            <Switch onChange={() => setSwitchTipos(prevState => !prevState)} checked={switchTipos}/>
          </Form.Item>
          <Form.Item label="Sistemas Operativos" style={{fontFamily:'Roboto-Medium', fontSize:'16px', marginBottom: 8}} labelAlign="left" valuePropName="checked">
            <Switch onChange={() => setSwitchSO(prevState => !prevState)} checked={switchSO}/>
          </Form.Item>
          <Form.Item label="Comportamientos" style={{fontFamily:'Roboto-Medium', fontSize:'16px', marginBottom: 8}} labelAlign="left" valuePropName="checked">
            <Switch onChange={() => setSwitchComportamientos(prevState => !prevState)} checked={switchComportamientos}/>
          </Form.Item>
        </Form>
      </Modal>
      </div>

      <div style={{ height: "100vh" }}>
        <div
          style={{ position: "absolute", top: 70, zIndex: 99 }}
        >
          <Radio.Group value={typeLocation} onChange={({ target }) => setTypeLocation(target.value)}>
            <Radio value={1}>Domicilio</Radio>
            <Radio value={2}>Trabajo</Radio>
          </Radio.Group>
          <div>
            <Input
              style={{ marginTop: 5, padding: 2, width: 50 }}
              type="color"
              defaultValue={minColor.current}
              onChange={e => minColor.current = e.target.value}
            />
            <Input
              style={{ padding: 2, width: 50 }}
              type="color"
              defaultValue={maxColor.current}
              onChange={e => maxColor.current = e.target.value}
            />
            <Button
              style={{ top: -4, left: 2 }}
              onClick={() => setColors([minColor.current, maxColor.current])}
            >
              Cambiar colores
            </Button>
          </div>
          <Legend minColor={colors[0]} maxColor={colors[1]} subscribers={maxCountLocation} />
        </div>
        <BaseMap
          ubicaciones={ubicaciones}
          setUbicaciones={setUbicaciones}
          filters={filters}
          minColor={colors[0]}
          maxColor={colors[1]}
          typeLocation={typeLocation}
          setMaxCountLocations={setMaxCountLocations}
          data={dataMap}
          onTop={setTopUbicaciones}
        />
      </div>
      <div style={{ marginTop: -80, paddingRight: "10%", paddingLeft: "10%" }}>
        <Affix offsetBottom={0}>
          <HeaderDetails
            data={dataTotal}
            filters={filters}
            setButtonClicked={setButtonClicked}
            setComportamientos={setComportamientos}
            setUbicaciones={setUbicaciones}
            topEdad={topEdad}
            topMarca={topMarca}
            setFiltersActived = {setFiltersActived}
            topDispositivos={topDispositivos}
            topIngresos={topIngresos}
            topGeneros={topGeneros}
            topComportamientos={topComportamientos}
            topUbicaciones={topUbicaciones}
            setRangeNavigationHour={setRangeNavigationHour}
            setRangeNavigationDate={setRangeNavigationDate}
            setDateNavigationRange={setDateNavigationRange}
            setHourNavigationRange={setHourNavigationRange}
            setSwitchUbicaciones={setSwitchUbicaciones}
            setSwitchGeneros={setSwitchGeneros}
            setSwitchEdades={setSwitchEdades}
            setSwitchIngresos={setSwitchIngresos}
            setSwitchMarcas={setSwitchMarcas}
            setSwitchTipos={setSwitchTipos}
            setSwitchSO={setSwitchSO}
            setModalFiltersOpen={setModalFiltersOpen}
            setSwitchComportamientos={setSwitchComportamientos}
            setGenero={setGenero}
            setIngreso={setIngreso}
            setEdad={setEdad}
            setMarca={setMarca}
            setSistemaOperativo={setSistemaOperativo}
            setTipo={setTipo}
            setFilters={setFilters}
            dataAudience={dataAudience}
            dateNavigationRange={dateNavigationRange}
            hourNavigationRange={hourNavigationRange}
            totalAudience={totalAudience}
            setDataMap={setDataMap}
            setDataDias={setDataDias} 
            setDataHoras={setDataHoras} 
            setDataGenero={setDataGenero}
            setDataIngreso={setDataIngreso}
            setDataEdad={setDataEdad} 
            setDataMarca={setDataMarca}
            setDataTipo={setDataTipo} 
            setDataSistema={setDataSistema}  
            setDataComportamientos={setDataComportamientos}
            totalNewAudience={totalNewAudience}
            params={params}
            totalBE={totalBE}
            dataBaseExternal={dataBaseExternal}
            loadingTotal={showLoaderTotal}
          />
        </Affix>
      </div>
      <div className="FiltrosAudiencica">
        {comportamientos.length != 0 ?
          <div className="FiltroAudiencia">
            Comportamientos:
            {comportamientos.map((item) => <span>{item.value_filter}</span>)}
          </div>
          : null
        }
        {ubicaciones.length != 0 ?
          <div className="FiltroAudiencia">
            Ubicaciones:
            {ubicaciones.map((item) => (
          <span>
            {item.description_filter === 'estado_home'
              ? 'Domicilio'
              : item.description_filter === 'estado_work'
              ? 'Trabajo'
              : 'Domicilio y trabajo'}: {item.value_filter}
          </span>
        ))}
          </div>
          : null
        }
        {genero.length != 0 ?
          <div className="FiltroAudiencia">
            Genero:
            {genero.map((item) => <span>{item.value_filter}</span>)}
          </div>
          : null
        }
        {ingreso.length != 0 ?
          <div className="FiltroAudiencia">
            Ingreso:
            {ingreso.map((item) => <span>{item.value_filter}</span>)}
          </div>
          : null
        }
        {edad.length != 0 ?
          <div className="FiltroAudiencia">
            Edad:
            {edad.map((item) => <span>{item.value_filter}</span>)}
          </div>
          : null
        }
        {marca.length != 0 ?
          <div className="FiltroAudiencia">
            Marca:
            {marca.map((item) => <span>{item.value_filter}</span>)}
          </div>
          : null
        }
        {tipo.length != 0 ?
          <div className="FiltroAudiencia">
            Tipo:
            {tipo.map((item) => <span>{item.value_filter}</span>)}
          </div>
          : null
        }
        {sistemaOperativo.length != 0 ?
          <div className="FiltroAudiencia">
            SO:
            {sistemaOperativo.map((item) => <span>{item.value_filter}</span>)}
          </div>
          : null
        }
        {dateNavigation.length != 0 ?
          <div className="FiltroAudiencia">
            Fecha:
            {
              (dateNavigation.length != 0) ?
                ((Array.isArray(dateNavigation[dateNavigation.length - 1])) ?
                  dateNavigation.map((item) => item.map(e => <span>{e.data.value_filter}</span>))
                  : null)
                : null
            }
          </div>
          : null
        }
        {dateNavigationRange && dateNavigationRange[0] != "" ?
          <div className="FiltroAudiencia">
            Rango de Fecha:
            <span>{dateNavigationRange[0]}, {dateNavigationRange[1]}</span>
          </div>
          : null
        }
        {hourNavigation.length != 0 ?
          <div className="FiltroAudiencia">
            Hora:
            {hourNavigation.map((item) => <span>{item.value_filter}</span>)}
          </div>
          : null
        }
        {hourNavigationRange && hourNavigationRange[0] != "" ?
          <div className="FiltroAudiencia">
            Rango de Horas:
            <span>{hourNavigationRange[0]} - {hourNavigationRange[1]}</span>
          </div>
          : null
        }
        {
          dbExternalName?
          <div className="FiltroAudiencia">
            BD externa:
            <span>{dbExternalName}</span>
          </div>:null
        }
      </div>
      <div className="ChartsGA">
        <ChartDivider title="TOTAL DE" stitle="SUSCRIPTORES" />
        <LineChart
          data={dataDiasTotales}
          showLoadingData={showLoaderDiasTotales}
          filters={filters}
          nameChart="TOTAL DE SUSCRIPTORES POR DÍA"
        />
        <br />
        {dateNavigationRange[0] !== "" ?
          <>
            <br />
            <LineChart
              data={dataDias}
              showLoadingData={showLoaderDias}
              filters={filters}
              nameChart="SUSCRIPTORES ÚLTIMOS DÍAS"
            />
          </>
          : null
        }
        {hourNavigationRange[0] !== "" ?
          <>
            <br />
            <LineChart
              data={dataHoras}
              showLoadingData={showLoaderHoras}
              filters={filters}
              nameChart="SUSCRIPTORES ÚLTIMAS HORAS"
            />
          </>
          : null
        }
        {(filtersActived || switchGeneros || switchEdades || switchIngresos) && (
        <ChartDivider title="DATOS" stitle="DEMOGRÁFICOS" />
        )}
        {(filtersActived || switchGeneros) && (
        <>
          <Donut
            genero={genero}
            filters={filters}
            data={dataGenero}
            onTop={setTopGeneros}
            showLoadingData={showLoaderGenero}
            nameChart="GÉNERO"
          />
          <br />
        </>)}
        {(filtersActived || switchIngresos) && (
        <>
        <Histogram
          data={dataIngreso}
          filter={ingreso}
          nameChart="INGRESO"
          showLoadingData={showLoaderIngreso}
          typeChart="Bar"
          onTop={setTopIngresos}
        />
        <br />       
        </>
        )}  
        {(filtersActived || switchEdades) && (
        <>
          <Histogram
            data={dataEdad}
            filter={edad}
            nameChart="EDAD"
            showLoadingData={showLoaderEdad}
            typeChart="Column"
            onTop={setTopEdad}
          />
          <br />
        </>
        )}    
        {(filtersActived || switchMarcas || switchTipos || switchSO) && (
          <ChartDivider title="DATOS DEL" stitle="DISPOSITIVO" />
        )}
        {(filtersActived || switchMarcas) && (
        <>
          <Histogram
            data={dataMarca}
            filter={marca}
            showLoadingData={showLoaderMarca}
            nameChart="MARCA"
            typeChart="Column"
            onTop={setTopMarca}
          />
          <br />
        </>
        )}
        {(filtersActived || switchTipos) && (
        <>
          <Histogram
            data={dataTipo}
            filter={tipo}
            showLoadingData={showLoaderTipo}
            nameChart="TIPO"
            typeChart="Column"
            onTop={setTopDispositivos}
          />
          <br />
        </>
        )}
        {(filtersActived || switchSO) && (
        <>
          <Histogram
            data={dataSistema}
            filter={sistemaOperativo}
            showLoadingData={showLoaderSistema}
            nameChart="SISTEMA OPERATIVO"
            typeChart="Column"
            onTop={setTopSO}
          />
          <br />
        </>
        )}
        {(filtersActived || switchComportamientos) && (
          <>
            <br />
            <ChartDivider title="COMPORTAMIENTOS" />
            <TableBehavoirs
              data={dataComportamientos}
              filters={filters}
              dataBaseExternal={dataBaseExternal}
              showLoadingData={showLoaderComportamientos}
              onTop={setTopComportamientos}
            />
        </>
        )}
      </div>
    </div>
  );
};

export default Audience;
