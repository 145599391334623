import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Space, Tag, Select } from 'antd';
import { remove } from 'lodash'
import { API } from 'aws-amplify';
import FiltersTable from '../../FiltersTable';
import * as custom_queries from "../../../../Custom_queries/queries";
import './Comportamientos.css'

const { Search } = Input;

const Comportamientos = (
  {
    comportamientos,
    setComportamientos,
  }
) => {
  const [selectedData1, setSelectedData1] = useState();
  const [searchValue1, setSearchValue1] = useState();
  const [showLoadingData1, setShowLoadingData1] = useState(false);
  const [selectedsData1, setSelectedsData1] = useState([]);
  const [banderaTags1, setBanderaTags1] = useState(false)
  const [isLike, setIsLike] = useState(true)
  const [searchPosition, setSearchPosition] = useState(3)
  const [searchBehavior, setSearchBehavior] = useState(false)

  const fetchFilter1 = async (filter = null, isLike, searchPosition) => {
    try {
      const response = await API.graphql({
        query: `query MyQuery {
          getFiltersRedshift(input: { limit: 1000, query: "getBehaviorCat", name_filter: "${filter}", is_like: ${isLike}, like_position: ${searchPosition} }){
            key
            description_filter
            type_filter
            value_filter
            id_statement
          }
        }`
      });
  
      const result = response.data.getFiltersRedshift;
  
      if (result[0].id_statement !== null) {
        getValues({ idStatement: result[0].id_statement });
      } else {
        const newData = result.map(item => {
          const { id_statement, ...rest } = item;
          return rest;
        });
  
        setSelectedData1(newData.length > 0 && newData[0].value_filter !== "Sin Datos" ? newData : []);
        setShowLoadingData1(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error appropriately (e.g., show an error message)
    }
  };
  
  useEffect(() => {
    setShowLoadingData1(true)
    fetchFilter1(searchValue1, isLike, searchPosition)
  }, [searchBehavior])
  
  const getValues = async ({ idStatement }) => {
    let response = [];
    while (response.length === 0) {
      const result = await API.graphql({
        query: `query MyQuery {
          getData(input: {id_statement: "${idStatement}", query: "getBehaviorCat"}){
            key
            description_filter
            type_filter
            value_filter
          }
        }`
      });
      response = result.data.getData;
      if (response.length === 0) {
        await new Promise(resolve => setTimeout(resolve, 5000)); // Esperar 5 segundos antes de hacer la siguiente petición
      }
    }
    setSelectedData1(response.length > 0 && response[0].value_filter !== "Sin Datos" ? response : [])
    setShowLoadingData1(false)
  };

  const handleChange = (value) => {
    switch (value) {
      case 1:
        // Total Audience
        setIsLike(true)
        setSearchPosition(3)
        break
      case 2:
        // New Audience
        setIsLike(false)
        setSearchPosition(0)
        break
      case 3:
        // CLM
        setIsLike(true)
        setSearchPosition(2)
        break
      case 4:
        // CLM
        setIsLike(true)
        setSearchPosition(1)
        break
      default:
        //undefined 
        break
    }
  };

  return (
    <Row className="Comportamientos">
      <Col span={8}>
        <Space direction="vertical">
          <Select
            defaultValue="Búsqueda por palabra contenida en comportamiento:"
            style={{
              width: 370,
              borderRadius: '5px',
              borderColor: 'blue',
              outline: 'none',
              boxShadow: '0 0 0 2px rgba(0, 0, 255, 0.2)',
            }}
            onChange={handleChange}
            bordered={false}
            dropdownStyle={{ borderRadius: '5px' }}
            options={[
              {
                value: 1,
                label: 'Búsqueda por palabra contenida en comportamiento:',
              },
              {
                value: 2,
                label: 'Búsqueda por palabra exacta del comportamiento:',
              },
              {
                value: 3,
                label: 'Búsqueda por palabra al inicio del comportamiento:',
              },
              {
                value: 4,
                label: 'Búsqueda por palabra al final del comportamiento:',
              },
            ]}
          />
          <Search
            placeholder="Buscar"
            allowClear
            loading={showLoadingData1}
            onSearch={(value) => {
              setShowLoadingData1(true)
              setSearchValue1(value)
              setSearchBehavior(!searchBehavior)
            }}
          />
        </Space>
        <FiltersTable
          selectedData={selectedData1}
          setSelectedData={setSelectedData1}
          selectedsData={selectedsData1}
          setSelectedsData={setSelectedsData1}
          filterComponent={comportamientos}
          setFilterComponent={setComportamientos}
          banderaTags={banderaTags1}
          setBanderaTags={setBanderaTags1}
          showLoadingData={showLoadingData1}
          setShowLoadingData={setShowLoadingData1}
        />
      </Col>
      <Col className="ResultSearch" span={16}>
        {
          selectedsData1 && selectedsData1.map(({ key, value_filter, type }) => (
            <Tag
              key={`${key}-${value_filter}`}
              className="TagsSelectFilters"
              closable
              onClose={e => {
                remove(selectedsData1, { value_filter: value_filter })
                setSelectedsData1([...selectedsData1])
                setBanderaTags1(true)
              }}
            >
              {value_filter}
            </Tag>
          )
          )
        }
      </Col>
    </Row>
  )
}
export default Comportamientos;