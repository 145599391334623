import { useEffect, useState } from "react";
import { message, Input, Button, Form, Radio, Col, Row, Select } from "antd";
import { API } from "aws-amplify";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "../ListUsers.css"


const CustomizedForm = ({
  onChange,
  fields,
  onFinish,
  onFinishFailed,
  mode,
  isRequired,
  setPhoneRequired,
  groupOptions,
  contextHolder
}) => (
  <>
    <br />
    {contextHolder}
    <Row>
      <div className="SignIn">
        <Col span={24}>
          {mode === "upd" ? (
            <div
              className="TituloBoldGA"
              style={{ textAlign: "center", fontSize: 22 }}
            >
              Actualización de usuario
            </div>
          ) : mode === "dlt" ? (
            <div
              className="TituloBoldGA"
              style={{ textAlign: "center", fontSize: 22 }}
            >
              Eliminación de usuario
            </div>
          ) : mode === "pswd" ? (
            <div
              className="TituloBoldGA"
              style={{ textAlign: "center", fontSize: 22 }}
            >
              Restablecimiento de contraseña
            </div>
          ) : (
            <div
              className="TituloBoldGA"
              style={{ textAlign: "center", fontSize: 22 }}
            >
              Creación de usuario
            </div>
          )}
        </Col>
        <br />
        <Form
          name="form-user"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          fields={fields}
          style={{ width: "400px", margin: "auto" }}
          onFieldsChange={(_, allFields) => {
            onChange(allFields);
          }}
        >
          {!mode && (
            <Form.Item
              label="Correo electrónico:"
              name="email"
              className="TextRegister"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              labelAlign="right"
              rules={[
                {
                  required: true,
                  message: "Ingrese correo electrónico.",
                },
                {
                  type: 'email',
                  message: 'El correo electrónico debe ser válido',
                },
              ]}
            >
              <Input
                style={{ marginTop: "-5px" }}
                size="large"
                disabled={mode === "dlt" ? true : false}
              />
            </Form.Item>
          )}
          {mode !== "pswd" && (<Row>
            <Col span={12}>
              <Form.Item
                label="Nombre"
                name="name"
                className="TextRegister"
                style={{ marginTop: "-10px" }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 23 }}
                rules={[
                  {
                    required: true,
                    message: "Ingrese primer nombre",
                  },
                ]}
              >
                <Input
                  style={{ marginTop: "-5px" }}
                  disabled={mode === "dlt" || mode === "upd" && "upd" ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Apellido"
                name="lastName"
                className="TextRegister"
                style={{ marginTop: "-10px" }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Ingrese apellidos",
                  },
                ]}
              >
                <Input
                  style={{ marginTop: "-5px" }}
                  disabled={mode === "dlt" || mode === "upd" ? true : false}
                />
              </Form.Item>
            </Col>
          </Row>)}
          {mode !== "pswd" && (<Form.Item
            label="Teléfono celular"
            name="phoneNumber"
            className="TextRegister"
            style={{ marginTop: "-10px" }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValue=''
            rules={[
              {
                required: isRequired,
                message: "Ingrese número celular",
              },
              {
                min: 10,
                message: 'La contraseña debe tener al menos 10 dígitos',
              },
              {
                pattern: /^\d{10}/,
                message: 'El número no debe contener letras',
              },
            ]}
          >
            <Input
              style={{ marginTop: "-5px" }}
              disabled={mode === "dlt" ? true : false}
            />
          </Form.Item>)}
          <Form.Item
            label="Username"
            name="username"
            className="TextRegister"
            style={{ marginTop: "-10px" }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Ingrese nombre de usuario",
              },
              {
                validator: async (_, value) => {
                  const regex = /^\S*$/;
                  if (!regex.test(value)) {
                    throw new Error('El nombre no puede contener espacios en blanco.');
                  }
                  const validador = await API.graphql({
                    query: `
                  query ListUsers(
                    $filter: ModelUserFilterInput
                    $limit: Int
                    $nextToken: String
                  ) {
                    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
                      items {
                        id
                        createdAt
                        rol
                        name
                        lastName
                        username
                      }
                    }
                  }`,
                    variables: { filter: {username: { eq: value }, is_deleted: {eq: false}} },
                  });
                  //console.log(validador)
                  if (validador.data.listUsers.items.length > 0 && !mode) {
                    throw new Error('No es posible ingresar este nombre de usuario, prueba con otro.');
                  }
                },
              }
            ]}
          >
            <Input
              style={{ marginTop: "-5px" }}
              disabled={mode ? true : false}
            />
          </Form.Item>
          {mode !== "pswd" && (
          <Form.Item
            label="Seleccionar grupo"
            name="groups"
            className="TextRegister"
            style={{ marginTop: "-10px" }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Seleccione un grupo",
              },
            ]}
          >
            <Select
              className="TextRegister"
              style={{ marginTop: "-10px" }}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              options={groupOptions}
              disabled={mode === "dlt"}
              optionLabelProp="label"
            />
          </Form.Item>)}
          {mode !== "dlt" && mode !== "upd" && (
            <Form.Item
              label="Password"
              name="password"
              className="TextRegister"
              style={{ marginTop: "-10px" }}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Ingrese contraseña",
                },
                {
                  min: 10,
                  message: "La contraseña debe tener al menos 10 caracteres",
                },
                {
                  pattern: /^(?=.*[a-z])/,
                  message: "La contraseña debe contener al menos una letra minúscula",
                },
                {
                  pattern: /^(?=.*[A-Z])/,
                  message: "La contraseña debe contener al menos una letra mayúscula",
                },
                {
                  pattern: /^(?=.*[\W_])/,
                  message: "La contraseña debe contener al menos un carácter especial",
                },
                {
                  pattern: /^(?=.*[0-9])(?!.*(012|123|234|345|456|567|678|789|890))/,
                  message: "La contraseña debe contener al menos un valor numérico y no debe tener patrones consecutivos",
                },
                {
                  pattern: /^(?!.*(Telcel|Mientras|Temporal|Soporte))/i,
                  message: "La contraseña no debe incluir palabras comunes o genéricas",
                },
                {
                  pattern: /^(?!.*(.)\1\1)/,
                  message: "La contraseña no debe contener patrones repetitivos de tres caracteres consecutivos",
                },
              ]}
            >
              <Input.Password style={{ marginTop: "-5px" }} />
            </Form.Item>
          )}
          {mode !== "pswd" && (<Form.Item
            label="Rol"
            name="rol"
            labelAlign="right"
            className="TextRegister"
            style={{ marginTop: "-10px" }}

            rules={[
              {
                required: true,
                message: "Ingrese rol",
              },
            ]}
          >
            <Radio.Group
              style={{ marginLeft: "50px" }}
              buttonStyle="solid"
              disabled={mode === "dlt" ? true : false}
              onChange={({ target }) => (target.value === 'Admin') ? setPhoneRequired(true) : setPhoneRequired(false)}
            >
              <Radio.Button value="Admin">Administrador</Radio.Button>
              <Radio.Button value="Company">Compañia</Radio.Button>
              <Radio.Button value="Customer">Cliente</Radio.Button>
            </Radio.Group>
          </Form.Item>)}
          
          {mode !== "pswd" && (<Form.Item
            label="Indicadores Fecha/Hora"
            name="indicadores"
            labelAlign="right"
            className="TextRegister"
            style={{ marginTop: "-10px" }}

            rules={[
              {
                required: true,
                message: "Es necesario asignar estado de la restricción",
              },
            ]}
          >
            <Radio.Group
              style={{ marginLeft: "50px" }}
              buttonStyle="solid"
              disabled={mode === "dlt" ? true : false}
            >
              <Radio.Button value="Si">Si</Radio.Button>
              <Radio.Button value="No">No</Radio.Button>
            </Radio.Group>
          </Form.Item>)}

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            {mode === "upd" ? (
              <Button
                style={{ marginLeft: "-10px" }}
                type="primary"
                className="SubtituloRegularGA Button1"
                htmlType="submit">
                Aceptar cambio
              </Button>
            ) : mode === "dlt" ? (
              <Button
                style={{ marginLeft: "-10px" }}
                type="primary"
                className="SubtituloRegularGA Button1"
                htmlType="submit">
                Eliminar usuario
              </Button>
            ) : mode === "pswd" ? (
              <Button
                style={{ marginLeft: "-10px" }}
                type="primary"
                className="SubtituloRegularGA Button1"
                htmlType="submit">
                Cambiar contraseña
              </Button>
            ) : (
              <Button
                style={{ marginLeft: "-30px" }}
                type="primary"
                className="SubtituloRegularGA Button1"
                htmlType="submit" >
                <span>Guardar nuevo usuario</span>
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </Row>
  </>
);

const User = () => {
  const [fields, setFields] = useState([]);
  const [phoneRequired, setPhoneRequired] = useState(false);
  const [previousRestriction, setPreviousRestriction] = useState();
  const [groupOptions, setGroupOptions] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get("mode");

  useEffect(() => {
    if (id) {
      const getUser = async (id) => {
        const usuario = await API.graphql({
          query: `
            query GetUser($id: ID!) {
                getUser(id: $id) {
                name
                rol
                name
                phoneNumber
                lastName
                username
                groups {
                  items {
                    group {
                      name
                      id
                    }
                  }
                }
                restrictions {
                  items {
                    id
                    restrictionID
                  }
                }
                }
            }`,
          variables: { id },
        });
        const indicadoresValue=usuario.data.getUser.restrictions.items.length > 0 ? "No" : "Si";
        setFields([
          {
            name: "name",
            value: usuario.data.getUser.name,
          },
          {
            name: "lastName",
            value: usuario.data.getUser.lastName,
          },
          {
            name: "rol",
            value: usuario.data.getUser.rol,
          },
          {
            name: "phoneNumber",
            value: usuario.data.getUser.phoneNumber.replace('+52', ''),
          },
          {
            name: "username",
            value: usuario.data.getUser.username,
          },
          {
            name: "groups",
            value: usuario.data.getUser.groups.items[0].group.id,
            label: usuario.data.getUser.groups.items[0].group.name,
          },
          {
            name: "indicadores",
            value: indicadoresValue,
          }
        ]);
        if (usuario.data.getUser.rol === 'Admin') setPhoneRequired(true)
        setPreviousRestriction(indicadoresValue)
      };
      getUser(id);
    }
  }, []);

  useEffect(() => {
    const getGroups = async () => {
      let nextToken = null;
      let allUsers = [];
      do {
        const allTodos = await API.graphql({
          query: `
            query ListGroups(
              $filter: ModelGroupFilterInput
              $nextToken: String
            ) {
              listGroups(filter: $filter, nextToken: $nextToken) {
                items {
                  name
                  id
                }
                nextToken
              }
            }
          `,
          variables: {
            filter: {is_deleted: {eq: false}},
            nextToken
          },
        });
        const items = allTodos.data.listGroups.items;
        allUsers = allUsers.concat(items);
        nextToken = allTodos.data.listGroups.nextToken;
      } while (nextToken !== null);

        const modifiedOptions = allUsers.map(user => ({
          value: user.id,
          label: user.name
        }));
        const opcionesOrdenadas = modifiedOptions.sort((a, b) => a.label.localeCompare(b.label));
        setGroupOptions(opcionesOrdenadas);
    }
    getGroups();
  }, []);


  const createUser = async (input) => { 
    if (input.indicadores === "No") {
      input.restrictions = ["date", "hour"];
    }
    delete input.indicadores;
    try{
      const usuario = await API.graphql({
        query: `
          mutation MyMutation($input: createUserInput) {
              createUserCustom(input: $input) {
                  id
              }
          }`,
        variables: { input },
      });
      navigate("/users");
      success("El usuario fue creado existosamente.")
    }catch{
      error("No fue posible crear el usuario, consulta al administrador.")
    }
  };

  const changePassword = async (input) => {
    delete input.groups
    delete input.indicadores
    try{
      const usuario = await API.graphql({
        query: `
          mutation MyMutation($input: passwordInput) {
            changePassword(input: $input) {
                  username
              }
          }`,
        variables: { input },
      });
      navigate("/users")
      success("La contraseña cambió exitosamente")
    }catch{
      error("No fue posible cambiar la contraseña, consulta al administrador.")
    }
  };
  
  const deleteUser = async (valor) => {
    let username = valor.username;
    const usuario = await API.graphql({
      query: `
        mutation MyMutation($input: deleteUserInput = {username: "${username}"}) {
          deleteUserCustom(input: $input)
        }`,
    });
    navigate("/users")
    if (usuario.data.deleteUserCustom==200){
      success("El usuario fue eliminado exitosamente.")
    }else{
      error("No fue posible eliminar al usuario, consulta al administrador.")
    }
  };

  const changeValues = async (valor, newRol, newGroup) => {
    const input = {
      groups: [newGroup],
      phoneNumber: valor.phoneNumber,
      rol: newRol,
      username: valor.username,
    };
    
    let usuario;

    if ((previousRestriction == 'Si' && valor.indicadores == 'No') || (previousRestriction == 'No' && valor.indicadores == 'Si')) {
      usuario = await API.graphql({
        query:`
          mutation {
            updateUserCustom(
              input: {
                id: "${id}"
                groups: "${input.groups}"
                phoneNumber: "${input.phoneNumber}"
                rol: "${input.rol}"
                username: "${input.username}"
                restrictions: ["date", "hour"]
              }
            )
          }`,
      });
    } else {
      usuario = await API.graphql({
        query:`
          mutation {
            updateUserCustom(
              input: {
                groups: "${input.groups}"
                phoneNumber: "${input.phoneNumber}"
                rol: "${input.rol}"
                username: "${input.username}"
              }
            )
          }`,
      });
    }
  
    navigate("/users");
    if (usuario.data.updateUserCustom==200){
      success("El usuario fue modificado exitosamente.")
    }else{
      error("No fue posible modificar la información del usuario, consulta al administrador.")
    }
  };
  
  const onFinish = async (values) => {
    switch (mode) {
      case "upd":
        const newRol = values.rol;
        const newGroup = values.groups;
        await changeValues(values, newRol, newGroup);
        break;
      case "dlt": 
        values.is_deleted = true;
        await deleteUser(values);
        break;
      case 'pswd':
        changePassword(values)
        break
      default:
        createUser(values);
        break;
    }
  };
  const onFinishFailed = (errorInfo) => {
    //console.log("Failed:", errorInfo);
  };

  const success = (valueSuccess) => {
    message.success(valueSuccess);
  };
  
  const error = (ValueError) => {
    message.error(ValueError);
  };
  
  return (
    <>
      {contextHolder}
      <CustomizedForm
        onChange={(newFields) => {
          setFields(newFields);
        }}
        fields={fields}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        mode={mode}
        groupOptions={groupOptions}
        isRequired={phoneRequired}
        setPhoneRequired={setPhoneRequired}
        contextHolder={contextHolder}
      />
    </>
  );
};

export default User;
